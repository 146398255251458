import React, { Fragment } from "react";
import { Col, Row } from "react-flexbox-grid";
import { ButtonIcon, Card } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faTrash,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

import getParts from "./vehicle/parts/parts";

export default class Pictures extends React.Component {
  onDeletePicture = (key) => {
    if (this.props.isEdit) {
      let tmpDataPart = this.props.dataPart;

      tmpDataPart[key].picture = undefined;

      this.props.updateFolder("dataPart", tmpDataPart);
    }
  };

  getPictureTagComponent = (picture, key, hideImagesId, updateHideImagesId) => {
    return (
      <div
        className="mt-10 ml-10"
        style={{
          backgroundColor: "var(--secondary-color)",
          display: "inline-flex",
          flexDirection: "column",
          borderRadius: "8px",
          overflow: "hidden",
        }}
        key={`div-${key}`}
      >
        {picture.tag ? (
          <div style={{ display: "flex", padding: "8px" }}>
            <span
              style={{ margin: "auto", color: "#ffffff", fontSize: "13px" }}
            >
              {this.getNameFromTag(picture.tag)}
            </span>
          </div>
        ) : null}
        <div className="d-flex" style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              padding: "5px",
              background: "#ff0000a1",
              borderRadius: "5px",
              ...(!hideImagesId.includes(picture.id)
                ? {
                    top: 5,
                    right: 15,
                  }
                : {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }),
            }}
            className="cursor-pointer"
            onClick={() => updateHideImagesId(picture.id)}
          >
            <FontAwesomeIcon
              size={hideImagesId.includes(picture.id) ? "7x" : undefined}
              icon={hideImagesId.includes(picture.id) ? faEyeSlash : faEye}
            />
          </div>
          <img
            key={`img-${picture.id}`}
            style={{
              height: 160,
              minWidth: 90,
              maxWidth: "100%",
              margin: "auto",
            }}
            loading="lazy"
            src={`/api/folder/get-picture-pixtransfer/${picture.id}/pixtransfer`}
            alt={`pixtransfer-${key}`}
          />
        </div>
      </div>
    );
  };

  getNameFromTag = (tag) => {
    let result = "";

    let car = [
      { tag: "car-avd", title: "3/4 AVD" },
      { tag: "tyre-avd", title: "PNEU AVD" },
      { tag: "insurance-1", title: "VIGNETTE CT / ASSURANCE" },
      { tag: "vin-1", title: "NUMÉRO DE SÉRIE" },
      { tag: "tyre-ard", title: "PNEU ARD" },
      { tag: "car-ard", title: "3/4 ARD" },
      { tag: "other1", title: "COFFRE ARRIÈRE" },
      { tag: "other2", title: "ROUE DE SECOURS" },
      { tag: "car-arg", title: "3/4 ARG" },
      { tag: "tyre-arg", title: "PNEU ARG" },
      { tag: "tyre-avg", title: "PNEU AVG" },
      { tag: "car-avg", title: "3/4 AVG" },
      { tag: "other-3", title: "PLANCHE DE BORD" },
      { tag: "other-10", title: "COMPTEUR" },
      { tag: "other-4", title: "CLÉS" },
      { tag: "other-5", title: "SELLERIE AVANT" },
      { tag: "other-6", title: "SELLERIE ARRIÈRE" },
      { tag: "other-7", title: "COMPARTIMENT MOTEUR" },
      { tag: "other-8", title: "NIVEAU D'HUILE" },
      {
        tag: "other-9",
        title: "NIVEAU DE LIQUIDE DE REFROIDISSEMENT",
      },
      {
        tag: "accessory-1",
        title: "ACCESSOIRES (PLUSIEURS PHOTOS)",
      },
      {
        tag: "invoice-1",
        title: "FACTURE ENTRETIEN/CT (PLUSIEURS PHOTOS)",
      },
      {
        tag: "damage-1",
        title: "DOMMAGES (PLUSIEURS PHOTOS)",
      },
      {
        tag: "previousDamage-1",
        title: "DOMMAGES ANTERIEURS (PLUSIEURS PHOTOS)",
      },
    ];

    let bikes = [
      { tag: "bike-avd", title: "3/4 AVD" },
      { tag: "tyre-av", title: "PNEU AV" },
      { tag: "brake-av", title: "FREIN AV" },
      { tag: "other-1", title: "JOINT SPY FOURCHE" },
      { tag: "insurance-1", title: "VIGNETTE ASSURANCE" },
      { tag: "vin-1", title: "NUMÉRO DE SÉRIE" },
      { tag: "bike-ard", title: "3/4 ARD" },
      { tag: "bike-arg", title: "3/4 ARG" },
      { tag: "other-2", title: "KIT CHAINE/COURROIE" },
      { tag: "tyre-ar", title: "PNEU AR" },
      { tag: "brake-ar", title: "FREIN AR" },
      { tag: "other-3", title: "SILENCIEUX AR" },
      { tag: "bike-avg", title: "3/4 AVG" },
      { tag: "other-3", title: "SELLE" },
      { tag: "other-4", title: "MOTEUR" },
      { tag: "other-5", title: "RÉSERVOIR" },
      { tag: "other-6", title: "COMPTEUR" },
      { tag: "other-7", title: "CLÉS" },
      {
        tag: "accessory-1",
        title: "ACCESSOIRES (PLUSIEURS PHOTOS)",
      },
      {
        tag: "invoice-1",
        title: "FACTURE ENTRETIEN",
      },
      {
        tag: "damage-1",
        title: "DOMMAGES",
      },
      {
        tag: "previousDamage-1",
        title: "DOMMAGES ANTERIEURS",
      },
    ];

    if (this.props.gender !== 1) {
      car.forEach((element) => {
        if (element.tag === tag) {
          result = element.title;
        }
      });
    } else {
      bikes.forEach((element) => {
        if (element.tag === tag) {
          result = element.title;
        }
      });
    }

    return result;
  };

  render() {
    const tmpDataPixTransfer = this.props.dataPixTransfer.reverse();

    const dataPixTransfer = [
      {
        title: "Identification",
        pictures: tmpDataPixTransfer.filter(
          (pictures) =>
            pictures.tag &&
            (pictures.tag.includes("car-") ||
              pictures.tag.includes("bike-") ||
              pictures.tag.includes("tyre-") ||
              pictures.tag.includes("brake-") ||
              pictures.tag.includes("insurance-") ||
              pictures.tag.includes("vin-") ||
              pictures.tag.includes("other-"))
        ),
      },
      {
        title: "Accessoires",
        pictures: tmpDataPixTransfer.filter(
          (pictures) => pictures.tag && pictures.tag.includes("previousDamage-")
        ),
      },
      {
        title: "Entretien / CT",
        pictures: tmpDataPixTransfer.filter(
          (pictures) => pictures.tag && pictures.tag.includes("invoice-")
        ),
      },
      {
        title: "Dommages",
        pictures: tmpDataPixTransfer.filter(
          (pictures) => pictures.tag && pictures.tag.includes("damage-")
        ),
      },
      {
        title: "Dommages antérieurs",
        pictures: tmpDataPixTransfer.filter(
          (pictures) => pictures.tag && pictures.tag.includes("previousDamage-")
        ),
      },
      {
        title: "Autres",
        pictures: tmpDataPixTransfer.filter((pictures) => !pictures.tag),
      },
    ];

    const parts = getParts(
      this.props.gender,
      this.props.dataIdentification && this.props.dataIdentification["Genre"]
        ? this.props.dataIdentification["Genre"][0]
        : null,
      this.props.dataIdentification &&
        this.props.dataIdentification["Categorie"]
        ? this.props.dataIdentification["Categorie"][0]
        : null
    );

    let dataPicture = [];
    let countPicture = 0;

    if (this.props.dataPart) {
      dataPicture = Object.keys(this.props.dataPart).map((key) => {
        if (this.props.dataPart[key].picture) {
          countPicture++;

          return (
            <Col key={key} xs={12} md={4} lg={3}>
              <div
                className="mb-10"
                style={{
                  backgroundColor: "var(--secondary-color)",
                  color: "var(--white)",
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  borderRadius: "8px",
                  fontSize: "13px",
                  textAlign: "center",
                }}
              >
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      right: 0,
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    {!this.props.isPrint ? (
                      <ButtonIcon
                        onClick={() => this.onDeletePicture(key)}
                        className="red"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </ButtonIcon>
                    ) : null}
                  </div>
                  {parts.hasOwnProperty(key) ? <p>{parts[key].name}</p> : null}
                </div>
                {this.props.dataPart[key].picture ? (
                  <>
                    {this.props.dataPart[key].mime &&
                    typeof this.props.dataPart[key].mime === "string" &&
                    this.props.dataPart[key].mime.includes("image") ? (
                      <img
                        style={{ maxHeight: "100%", maxWidth: "100%" }}
                        src={this.props.dataPart[key].picture}
                        alt={`part-${key}`}
                      />
                    ) : (
                      <span className="pb-20 m-auto">
                        <FontAwesomeIcon icon={faFile} />
                      </span>
                    )}
                  </>
                ) : null}
              </div>
            </Col>
          );
        } else {
          return null;
        }
      });
    }

    return (
      <Card
        title="Photos / Documents"
        className="mt-10 mt-20-xl"
        action={
          <FontAwesomeIcon icon={this.props.hideImage ? faEyeSlash : faEye} />
        }
        onClick={this.props.updateHideImage}
      >
        {!this.props.hideImage && !this.props.isPrint ? (
          <>
            {countPicture ? <Row>{dataPicture}</Row> : null}
            {dataPixTransfer.map((group, idx) => {
              if (group.pictures.length) {
                return (
                  <Fragment key={idx}>
                    <h3>{group.title}</h3>
                    {group.pictures.map((picture, pictureIdx) =>
                      this.getPictureTagComponent(
                        picture,
                        pictureIdx,
                        this.props.hideImagesId ? this.props.hideImagesId : [],
                        this.props.updateHideImagesId
                      )
                    )}
                  </Fragment>
                );
              } else {
                return null;
              }
            })}
            {!countPicture && !tmpDataPixTransfer.length ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100px",
                  border: "solid 2px var(--gray)",
                  borderRadius: "15px",
                }}
              >
                <p style={{ margin: "auto", color: "var(--gray)" }}>
                  Aucune photo
                </p>
              </div>
            ) : null}
          </>
        ) : null}
      </Card>
    );
  }
}
